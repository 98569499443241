<template>
  <ion-item>
    <ion-label position="stacked">
      {{ $t(field.label) }}
    </ion-label>

    <ion-toggle :checked="toggled" @ionChange="toggleChanged" />
  </ion-item>
</template>

<script>
    export default {
      props: ['field', 'model'],
      data() {
        return {
          toggled: false
        }
      },
      created() {
        this.initializeToggle()
      },
      methods: {
        initializeToggle() {
          this.toggled = this.model[this.field.userKey] ? true : false;
        },
        toggleChanged()
        {
          this.toggled = !this.toggled;
          this.$emit('inputChanged', this.toggled)
        }
      }
    }
</script>

<style scoped >


    ion-toggle {
        zoom: 0.8;
        margin-bottom: 5px;
    }
</style>
