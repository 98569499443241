<template>
  <ion-item>
    <ion-label position="stacked">
      {{ $t(field.label) }}
    </ion-label>

    <ion-datetime
      v-if="field.type == 'date'"
      display-format="DD-MM-YYYY"
      :value="model[field.userKey]"
      picker-format="DD MM YYYY"
      :required="field.required"
      @ionChange="dateChanged($event)"
    />
  </ion-item>
</template>

<script>
    export default {
      props: ['field', 'model'],
      methods: {
        dateChanged(event) {
          this.$emit('inputChanged', event)
        }
      }
    }
</script>

<style scoped>

</style>
