<template>
  <ion-list>
    <ion-radio-group :value="model[field.userKey]" @ionChange="changedGender($event)">
      <ion-list-header>
        <ion-label>{{ $t(field.label) }}</ion-label>
      </ion-list-header>
      <ion-item>
        <ion-label>{{ $t('male') }}</ion-label>
        <ion-radio slot="end" color="success" value="MALE" />
      </ion-item>

      <ion-item>
        <ion-label>{{ $t('female') }}</ion-label>
        <ion-radio slot="end" color="success" value="FEMALE" />
      </ion-item>
    </ion-radio-group>
  </ion-list>
</template>

<script>
    export default {
      props: ['field', 'model'],
      data() {
        return {
          gender: null
        }
      },
      created() {
        this.gender = (this.model[this.field.userKey]) ? this.model[this.field.userKey] : null
      },
      methods: {
        changedGender(event) {
          this.$emit('inputChanged', event)
        }
      }
    }
</script>

<style scoped>

</style>
