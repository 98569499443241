<template>
  <ion-item>
    <ion-label position="stacked">
      {{ $t(field.label) }}
    </ion-label>

    <ion-select
      :placeholder="field.label"
      :selected-text="selectedText"
      :value="selectedId"
      @ionChange="nationalityChanged($event)"
      @click="bindScrollToSelected"
    >
      <ion-select-option v-for="nationality in nationalities" :key="nationality.id" :value="nationality.id">
        {{ nationality.country }}
      </ion-select-option>
    </ion-select>
  </ion-item>
</template>

<script>
    import { GET_NATIONALITIES } from "@/graphql/queries";
    import ScrollToSelectedMixin from "@/mixins/scrollToSelected.mixin";

    export default {
      props: ['field', 'model'],
      mixins: [ScrollToSelectedMixin],
      data() {
        return {
          selectedId: null,
          selectedText: null,
        }
      },
      created() {
        this.setInitialSelected()
      },
      methods: {
        setInitialSelected() {
          if (this.model[this.field.userKey]) {
            this.selectedText = this.model[this.field.userKey].country
            this.selectedId = this.model[this.field.userKey].id
          }
        },
        nationalityChanged(event) {
          this.selectedText = this.nationalities.filter(nationality => nationality.id == event.target.value)[0].country;
          this.$emit('inputChanged', event)
        }
      },
      apollo: {
        nationalities: GET_NATIONALITIES
      },
    }
</script>
