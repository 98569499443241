/**
 * Mixin to support scrolling to the selected option in an ion-select
 *
 * This solves issue:
 * https://github.com/ionic-team/ionic-framework/issues/19296
 *
 * To use it, add this mixin to your component and add
 *
 *   @click="bindScrollToSelected" to the ion-select
 */
export default {
  data() {
    return {
      scrollToSelectedBound: false,
    }
  },
  methods: {
    beforeDestroy() {
      this.unbindScrollToSelected();
    },
    bindScrollToSelected() {
      if (!this.scrollToSelectedBound) {
        this.scrollToSelectedBound = true;
        window.addEventListener('ionAlertWillPresent', this.scrollWhenPresenting);
      }
    },
    unbindScrollToSelected() {
      if (this.scrollToSelectedBound) {
        window.removeEventListener('ionAlertWillPresent', this.scrollWhenPresenting)
        this.scrollToSelectedBound = false;
      }
    },
    scrollWhenPresenting() {
      // Unbind immediately, allows us to re-bind with another select component
      this.unbindScrollToSelected();

      let selectedOption = null
      const options = document.getElementsByClassName('alert-radio-button')
      if (options.length) {
        for (const option of options) {
          const attribute = option.attributes.getNamedItem('aria-checked')
          if (attribute && attribute.value === 'true') {
            selectedOption = option
            break
          }
        }
      }
      if (selectedOption) {
        // Give the UI time to display the component
        this.$nextTick(() => {
          selectedOption.scrollIntoView({block: 'center'})
        })
      }
    }
  }
}