<template>
  <ion-item>
    <ion-label position="stacked">
      {{ $t(field.label) }}
    </ion-label>

    <ion-select :placeholder="field.label" :selected-text="selectedText" :value="selectedId" @ionChange="bloodTypeChanged($event)">
      <ion-select-option v-for="bloodtype in bloodtypes" :key="bloodtype.id" :value="bloodtype.id">
        {{ bloodtype.name }}
      </ion-select-option>
    </ion-select>
  </ion-item>
</template>

<script>
  import { GET_BLOOD_TYPES } from "@/graphql/queries";

  export default {
    props: ['field', 'model'],
    apollo: {
      bloodtypes: GET_BLOOD_TYPES
    },
    data() {
      return {
        selectedText: '',
        selectedId: null
      }
    },
    mounted() {
      this.setInitialSelectedText()
      this.setInitialSelectedId()
    },
    methods: {
      setInitialSelectedId() {
        this.selectedId = this.model[this.field.userKey] && this.model[this.field.userKey].id
      },
      setInitialSelectedText() {
        this.selectedText = this.model[this.field.userKey] && this.model[this.field.userKey].name
      },
      bloodTypeChanged(event) {
        this.selectedText = this.bloodtypes.filter(bloodtype => bloodtype.id == event.target.value)[0].name;
        this.$emit('inputChanged', event);
      }
    }
  }
</script>

<style scoped>

</style>
