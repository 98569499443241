<template>
  <ion-list class="my-4">
    <ion-list-header>
      <ion-label>{{ $t(field.label) }}</ion-label>
    </ion-list-header>

    <ion-item>
      <ion-label position="stacked">
        {{ $t('namefamilydoctor') }}
      </ion-label>

      <ion-input type="text" :value="model['doctor_name']" @ionChange="changeDoctorName($event)" />
    </ion-item>

    <ion-item>
      <ion-label position="stacked">
        {{ $t('phonefamilydoctor') }}
      </ion-label>

      <ion-input type="text" :value="model['doctor_tel']" @ionChange="changeDoctorPhone($event)" />
    </ion-item>
  </ion-list>
</template>

<script>
    export default {
      props: ['field', 'model'],
      methods: {
        changeDoctorName(event)
        {
          this.$emit('nameChanged', event);
        },
        changeDoctorPhone(event)
        {
          this.$emit('phoneChanged', event);
        }
      }
    }
</script>

<style scoped>

</style>
