<template>
  <ion-item>
    <ion-label position="stacked">
      {{ $t(field.label) }}
    </ion-label>

    <ion-input type="text" :value="model[field.userKey]" :required="field.required" @ionChange="inputChanged($event)" />
  </ion-item>
</template>

<script>
    export default {
      props: ['field', 'model'],
      methods: {
        inputChanged(event) {
          this.$emit('inputChanged', event);
        }
      }
    }
</script>

<style scoped>

</style>
