<template>
  <ion-list class="my-4">
    <ion-list-header>
      <ion-label>{{ $t(field.label) }}</ion-label>
    </ion-list-header>

    <ion-radio-group v-if="yourItems && yourItems.length" @ionChange="changeRadio">
      <ion-list-header>
        <ion-label>{{ $t('selectoneofyourbikes') }}</ion-label>
      </ion-list-header>
      <ion-item v-for="yourItem in yourItems" :key="yourItem.id">
        <ion-label>{{ yourItem.name }}</ion-label>
        <ion-radio slot="start" color="success" :value="yourItem.id" :checked="yourItem.pivot.is_default" />
        <ion-button style="position: relative; z-index: 99999999" @click="removeRadioItem(yourItem.id)">
          <ion-icon name="close" />
        </ion-button>
      </ion-item>
    </ion-radio-group>

    <ion-item>
      <ion-label position="stacked">
        {{ $t('make') }}
      </ion-label>

      <ion-select :placeholder="$t('make')" :aria-required="field.required" @ionChange="toggleMake($event.target.value)">
        <ion-select-option v-for="make in bikeMakes" :key="make.id" :value="JSON.stringify(make)">
          {{ make.make }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item v-if="selectedMake">
      <ion-label position="stacked">
        {{ $t('yearofbuild') }}
      </ion-label>

      <ion-select :placeholder="$t('yearofbuild')" :aria-required="field.required" @ionChange="toggleBuildYear($event.target.value)">
        <ion-select-option v-for="buildYear in buildYears" :key="buildYear" :value="buildYear">
          {{ buildYear }}
        </ion-select-option>
      </ion-select>
    </ion-item>


    <ion-item v-if="models.length">
      <ion-label position="stacked">
        {{ $t('model') }}
      </ion-label>

      <ion-select :placeholder="$t('model')" :aria-required="field.required" @ionChange="bikeChanged($event)">
        <ion-select-option v-for="(model, index) in models" :key="index" :value="model.id" :selected="yourItemsIds.includes(model.id)">
          {{ model.model }}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list>
</template>

<script>
    import { GET_BIKE_MAKES } from "@/graphql/queries";

    export default {
      props: ['field', 'model'],
      data() {
        return {
          selectedMake: null,
          selectedBuildYear: null,
          buildYears: [],
          yourItems: [],
        }
      },
      computed: {
        yourItemsIds() {
          return this.yourItems.map(yourItem => yourItem.id);
        },
        models() {
          if(this.selectedMake && this.selectedBuildYear) {
            return this.selectedMake.bikes.filter((bike) => this.selectedBuildYear == bike.build_year)
          }

          return [];
        }
      },
      created() {
        this.yourItems = this.model[this.field.userKey].map(bike => this.factoryBikeObject(bike))
      },
      methods: {
        bikeChanged(event) {
          const selectedBike = event.target.value;
          let foundBike = this.selectedMake.bikes.find(bike => bike.id == selectedBike);

          let newBike;
          if (foundBike) {
            newBike = {
              id: foundBike.id,
              make: this.selectedMake.name,
              name: `${this.selectedMake.make} ${foundBike.model} ${foundBike.build_year}`,
              pivot: {
                is_default: true
              }
            }
          }

          this.yourItems = [...this.yourItems, newBike];

          this.selectedMake = null;
          this.selectedBuildYear = null;

          this.emitChanges();
        },
        factoryBikeObject(bike) {
          return {
            id: bike.id,
            make: bike.bike_make.make,
            name: `${bike.bike_make.make} ${bike.model} ${bike.build_year}`,
            pivot: {
              is_default: bike.pivot.is_default
            }
          }
        },
        changeRadio(event) {
          this.yourItems = this.yourItems.map(yourItem => {
            yourItem.pivot.is_default = false;

            if(event.target.value === yourItem.id) {
              yourItem.pivot.is_default = true
            }

            return yourItem;
          });

          this.emitChanges();
        },
        emitChanges() {
          this.$emit('inputChanged', this.yourItems);
        },
        removeRadioItem(id) {
          this.yourItems = this.yourItems.filter(yourItem => yourItem.id != id);
          this.emitChanges();
        },
        toggleBuildYear(value) {
          this.selectedBuildYear = value;
        },
        toggleMake(value) {
          this.selectedMake = JSON.parse(value);
          this.selectedBuildYear = null;
          this.buildYears = this.selectedMake.bikes.map(bike => bike.build_year).sort();
        }
      },
      apollo: {
        bikeMakes: GET_BIKE_MAKES
      }
    }
</script>