<template>
  <ion-list class="my-4">
    <ion-list-header>
      <ion-label>{{ $t(field.label) }}</ion-label>
    </ion-list-header>

    <ion-item>
      <ion-label position="stacked">
        {{ $t('namecontactperson') }}
      </ion-label>

      <ion-input type="text" :value="model['contact_person_name']" @ionChange="changeEmergencyName($event)" />
    </ion-item>

    <ion-item>
      <ion-label position="stacked">
        {{ $t('phonecontactperson') }}
      </ion-label>

      <ion-input type="text" :value="model['contact_person_tel']" @ionChange="changeEmergencyPhone($event)" />
    </ion-item>
  </ion-list>
</template>

<script>
  export default {
    props: ['field', 'model'],
    methods: {
        changeEmergencyName(event) {
          this.$emit('nameChanged', event);
        },
        changeEmergencyPhone(event) {
          this.$emit('phoneChanged', event);
        }
    }
  }
</script>

<style scoped>



</style>
