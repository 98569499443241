<template>
  <ion-list class="my-4" full>
    <ion-list-header>
      <ion-label>{{ $t(field.label) }}</ion-label>
    </ion-list-header>

    <ion-radio-group v-if="yourItems && yourItems.length" :value="selectedRadioItem" @ionChange="changeRadio">
      <ion-list-header>
        <ion-label>{{ $t(field.radioLabel) }}</ion-label>
      </ion-list-header>
      <ion-item v-for="yourItem in yourItems" :key="yourItem.id">
        <ion-label>{{ yourItem.name }}</ion-label>
        <ion-radio slot="start" color="success" :value="yourItem.id" :checked="yourItem.pivot.is_default" />
        <ion-button style="position: relative; z-index: 99999999" @click="removeRadioItem(yourItem.id)">
          <ion-icon name="close" />
        </ion-button>
      </ion-item>
    </ion-radio-group>

    <ion-item>
      <ion-label position="stacked">
        {{ $t(field.selectLabel) }}
      </ion-label>

      <ion-select v-if="!error" :value="selectedItem" @ionChange="selectionChanged">
        <ion-select-option v-for="(selectItem, key) in selectItems" :key="selectItem.id" :disabled="yourItemsIds.includes(selectItem.id)" :value="key">
          {{ selectItem.name }}
        </ion-select-option>
      </ion-select>
      <div v-else>
        {{ error }}
      </div>
    </ion-item>
  </ion-list>
</template>

<script>
    import { GET_FEDERATIONS, GET_SPORTS } from '../../graphql/queries';
      import { formattedGraphQLErrorMessage } from '@/utils/helpers';

    export default {
      props: ['field', 'model'],
      data() {
        return {
          selectedItem: null,
          selectItems: [],
          yourItems: [],
          error: ''
        }
      },
      computed: {
        yourItemsIds() {
          if(this?.yourItems?.length) {
            return this.yourItems.map(sport => sport.id);
          }

          return [];
        },
        selectedRadioItem() {
          return this.yourItems.find(sport => sport.pivot.is_default === true)?.id;
        }
      },
      created() {
        this.setSelectItems();
        this.setRadioItems();
      },
      methods: {
        async fetchMultiSelect(query) {
          try {
            const response = await this.$apollo.query({
              query: query,
            });

            return response;
          } catch(error) {
            this.error = formattedGraphQLErrorMessage(error.message)
          }
        },
        async setSelectItems() {
          var response;

          switch(this.field.renderType) {
            case 'federations':
              response = await this.fetchMultiSelect(GET_FEDERATIONS);

              this.selectItems = response.data.federations.map(federation => {
                return {
                  id: federation.id,
                  name: federation.system_name
                }
              })
              break;
            case 'sports':
              response = await this.fetchMultiSelect(GET_SPORTS);

              this.selectItems = response.data.sports.map(sport => {
                return {
                  id: sport.id,
                  name: sport.name
                }
              })
              break;
            default:
              throw new Error(`The ${this.field.type} is not supported for multi select`);
          }
        },
        setRadioItems() {
          if(this?.model?.[this.field.userKey]?.length) {
            switch(this.field.renderType) {
              case 'federations':
                this.yourItems = this.model[this.field.userKey].map(federation => {
                  return {
                    id: federation.id,
                    name: federation.system_name,
                    pivot: {
                      is_default: federation.pivot.is_default
                    }
                  }
                })
                break;
              case 'sports':
                this.yourItems = this.model[this.field.userKey].map(sport => {
                  return {
                    id: sport.id,
                    name: sport.name,
                    pivot: {
                      is_default: sport.pivot.is_default
                    }
                  }
                })
                break;
              default:
                throw new Error(`Cannot set radio items for field type ${this.field.type}`)
            }
          }
        },
        changeRadio(event) {
          this.yourItems = this.yourItems.map(yourItem => {
            yourItem.pivot.is_default = false;

            if(event.target.value === yourItem.id) {
              yourItem.pivot.is_default = true
            }

            return yourItem;
          });

          this.emitChanges();
        },
        removeRadioItem(id) {
          const removing = this.yourItems.find(yourItem => yourItem.id == id);
          this.yourItems = this.yourItems.filter(yourItem => yourItem.id != id);

          // Make a different item the default
          if (removing.pivot.is_default && this.yourItems.length) {
            this.$set(this.yourItems[0], 'pivot', { is_default: true} );
          }

          this.clearSelection();
          this.emitChanges();
        },
        clearSelection() {
          this.selectedItem = null;
        },
        selectionChanged(event) {
          this.selectedItem = event.target.value;

          // Selection was cleared, do not add another item
          if (!this.selectedItem) {
            return;
          }

          this.yourItems.forEach((sport, key) => {
            this.yourItems[key].pivot.is_default = false;
          })

          let newSport = this.selectItems[event.target.value];
          this.$set(newSport, 'pivot', { is_default: true });
          this.yourItems = [...this.yourItems, newSport]

          this.emitChanges();
        },
        emitChanges() {
          this.$emit('inputChanged', this.yourItems);
        }
      },
    }
</script>

<style scoped>

</style>
