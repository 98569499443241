<template>
  <ion-item>
    <ion-label position="stacked">
      {{ field.label }}
    </ion-label>

    <ion-textarea
      ref="textarea"
      :value="model[field.userKey]"
      :required="field.required"
      rows="2"
      auto-grow="true"
      @ionChange="inputChanged($event)"
    />
  </ion-item>
</template>

<script>
    export default {
      props: ['field', 'model'],
      methods: {
        inputChanged(event) {
          this.$emit('inputChanged', event)
        }
      }
    }
</script>

<style scoped>

</style>
